import React from 'react';
import '../assets/styles/style.css'; 
import { Link } from 'react-router-dom';
import FacebookIcon from '../assets/images/facebook-icon.png';
import InstagramIcon from '../assets/images/instagram-icon.png';
import TwitterIcon from '../assets/images/twitter-icon.png';
import TikTokIcon from '../assets/images/tiktok-icon.png';
import YouTubeIcon from '../assets/images/youtube-icon.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="wave-footer"></div>
      <div className="footer-container">
        <div className="footer-column" id="contact">
          <h3>Contact</h3>
          <p>Location: 5th Floor F5-506, Ikaze House, Kisementi, Remera</p>
          <p>Email: info@songstarktours.com</p>
          <p>Tel: +250 794 422 747</p>
        </div>
        <div className="footer-column">
          <h3>Company</h3>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/tours">Tours packages</Link></li>
            <li><Link to="/plan-trip">Plan your trip</Link></li>
            <li><Link to="/contact">Contact us</Link></li>
            <li><Link to="#">Data Policy</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Support</h3>
          <ul>
            <li><Link to="#">Get in Touch</Link></li>
            <li><Link to="#">Help center</Link></li>
            <li><Link to="#">Live chat</Link></li>
          </ul>
        </div>
        <div className="footer-column" id="newsletter">
          <h3>Newsletter</h3>
          <p>Subscribe to the free newsletter and stay up to date</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Your email address" />
            <button id="newsletter-submit" type="submit">Send</button>
          </form>
          <h3 id="h3-follow">Follow Us On</h3>
          <div className="social-icons">
            <Link to="https://www.facebook.com/profile.php?id=61561126642115" target="_blank"><img src={FacebookIcon} alt="Facebook" /></Link>
            <Link to="https://www.instagram.com/songstark_tours?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank"><img src={InstagramIcon} alt="Instagram" /></Link>
            <Link to="https://twitter.com/songstarktours" target="_blank"><img src={TwitterIcon} alt="Twitter" /></Link>
            <Link to="https://www.tiktok.com/@songstarktours" target="_blank"><img src={TikTokIcon} alt="TikTok" /></Link>
            <Link to="https://www.youtube.com/@songstarktours" target="_blank"><img src={YouTubeIcon} alt="YouTube" /></Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; Copyright 2024. Song & Stark Cooperation Rwanda Ltd. All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;