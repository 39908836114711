import React, { useState, useEffect } from 'react';
import { API_URL } from '../variables';
import axios from 'axios';
import SectionLoader from './SectionLoader';

// Separate Review component
const ReviewCard = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="review-card">
      <div className="stars">{'★'.repeat(review.rating)}</div>
      <p className={`review-text ${isExpanded ? 'expanded' : ''}`}>
        {review.comment}
      </p>
      <button 
        className="read-more-btn" 
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? 'show less' : 'read more'}
      </button>
      <div className="review-author-info">
        <h4>{review.names}</h4>
        <span>{review.title}</span>
      </div>
    </div>
  );
};

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${API_URL}/reviews/getAllReviews`);
        setReviews(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError('Failed to load reviews');
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) return <SectionLoader />;
  if (error) return <div>{error}</div>;

  return (
    <section className="reviews-section">
      <div className="reviews-header">
        <h2>Reviews</h2>
      </div>
      <div className="reviews-grid">
        {reviews.map((review, index) => (
          <ReviewCard key={index} review={review} />
        ))}
      </div>
    </section>
  );
};

export default Reviews; 