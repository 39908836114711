import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import "../assets/styles/surveyForm.css";
import { API_URL } from '../variables';
import { FaMapMarkerAlt } from 'react-icons/fa';

const api_url = API_URL + '/reservations/createReservation';

const SurveyForm = ({ packageId, price, destination }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    people: '',
    date: '',
  });
  
  const [reservationSuccess, setReservationSuccess] = useState(false); 

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const reservationData = {
      reservation_name: formData.name,  
      reservation_email: formData.email,  
      reservation_phone: formData.phone, 
      reservation_message: formData.message,
      people: formData.people,
      package_id: packageId,    
      reservation_date: formData.date, 
    };
    
    console.log('Reservation Data:', reservationData);

    try {
      const response = await axios.post(
        api_url,
        JSON.stringify(reservationData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(`Reservation made successfully! We'll contact you soon at ${formData.email}`);
        setFormData({ name: '', email: '', phone: '', message: '' }); 
        setReservationSuccess(true); 
      }
    } catch (error) {
      console.error('Error submitting reservation', error);
      toast.error('There was an issue submitting your reservation. Please try again.');
      setReservationSuccess(false); 
    }
  };

  return (
    <div className="info-section">
      <h3>Destination: <span className="details-destination"><FaMapMarkerAlt/> {destination}</span></h3>
      <h3>From: <span className="details-price">${price}</span></h3>
      <p>price varies according to the number of travelers</p>
      
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="people"
          placeholder="Number of travelers"
          min="1"
          max="100"
          value={formData.people}
          onChange={handleChange}
          className="styled-input"
          required
        />
        
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="styled-datepicker-input"
          required
        />
        
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        
        <textarea
          name="message"
          placeholder="Special Requirements..."
          value={formData.message}
          onChange={handleChange}
          rows="3"
        />
        
        <button type="submit" className="btn-yellow">Book Now</button>
      </form>
      
      <Toaster position="top-center" reverseOrder={false} />
      {reservationSuccess && (
        <div className="response">
          <p>Thanks for reserving with us! We'll contact you via email for further information.</p>
        </div>
      )}
    </div>
  );
};

export default SurveyForm;
