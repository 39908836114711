import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/style.css';
import akagera1 from '../assets/images/trip.jpg';
import akagera2 from '../assets/images/hero1.avif';
import akagera3 from '../assets/images/optimized_main.png';

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [akagera1, akagera2, akagera3];

  // Arrays of dynamic titles and descriptions
  const titles = [
    'Come on a journey!',
    'Discover  Africa!',
    'Step into the Wild!',
  ];

  const descriptions = [
    'Discover Rwanda’s breathtaking landscapes and vibrant culture on a journey of a lifetime. Embark on an unforgettable adventure and immerse yourself in the beauty and wonders of Rwanda’s rich wildlife.',
    'Witness the majestic beauty of Africa’s  heart in Rwanda’s  diverse landscapes. From mountain gorillas in misty forests to lions roaming vast savannas, from vibrant cultural experiences to serene lake adventures - this is Africa at its finest.',
    'Rwanda offers more than just scenery. Dive into its rich history, enjoy the warmth of the people, and get close to nature like never before.',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <section id="hero">
        <div className="hero-slideshow">
          {images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentImageIndex ? 'active' : ''}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        <div className="overlay">
          <h1>{titles[currentImageIndex]}</h1>
          <p>{descriptions[currentImageIndex]}</p>
          <Link to="/plan-trip">
            <button>Book Now</button>
          </Link>
        </div>
      </section>
      <div className="wave"></div>
    </>
  );
};

export default Hero;
