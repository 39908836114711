import React from 'react'
import RttaImage from '../assets/images/rtta.png';
import RdbImage from '../assets/images/rdb.png';
import AkageraImage from '../assets/images/akagera.png';
import TripadvisorImage from '../assets/images/logo-trip.jpg';
import SafariImage from '../assets/images/logo-safaribookings.png';
import '../assets/styles/style.css';
function Partners() {
  return (
    <section id="ourpartners">
    <h2>Partners</h2>
    <div className="partners">
      <img src={RttaImage} alt="RTTA Logo" />
      <img src={RdbImage} alt="RDB Logo" />
      <img src={AkageraImage} alt="AKAGERA AVIATION Logo" />
      <img src={TripadvisorImage} alt="Tripadvisor Logo" />
      <img src={SafariImage} alt="Safari Logo" />
    </div>
  </section>
  )
}

export default Partners